import React from "react";
import { NavLink } from "react-router-dom";
import { PAGES_URLS } from "../../../config/pages-url.config";
import StatsIcon from "../../ui/icons/SideBar/StatsIcon";

export default function StatisticsLink({ open }) {
  return (
    <NavLink
      to={PAGES_URLS.STATISTICS}
      className={({ isActive }) =>
        isActive ? "text-[#EE6F42] " : "text-white"
      }
    >
      <li
        className={` flex gap-[6px] mb-[26px] hover:text-[#EE6F42] cursor-pointer`}
      >
        <span className="w-[24px]">
          <StatsIcon />
        </span>
        <span className={`${open ? "visible w-[192px] text-[18px]" : "hidden"}`}>
          Статистика
        </span>
      </li>
    </NavLink>
  );
}
