import React, { useState } from "react";
import StatisticsLink from "./StatisticsLink/StatisticsLink";
import PartnersLinks from "./PartnersLinks/PartnersLinks";
import ShevronIcon from "../ui/icons/SideBar/ShevronIcon";
import NavbatArrow from "../ui/icons/SideBar/NavbatArrow";
import NavbatIcon from "../ui/icons/SideBar/NavbatIcon";
import Logout from "./Logout/Logout";

export default function SideBar() {
  const [open, setOpen] = React.useState(true);
  const [showDropdown, setShowDropdown] = useState(false);

  const onDrawerClick = () => {
    setOpen(!open);
    setShowDropdown(false);
  };

  return (
    <section
      className={`pt-[32px] min-h-screen bg-[#1E3148] transition-all duration-250 ${
        open ? "w-[193px] px-[18px]" : "w-[72px] px-[24px]"
      }`}
    >
      <ul className="relative flex h-full flex-col justify-between">
        <div className={`text-white sticky top-8 overflow-x-hidden`}>
          <li className="flex gap-0.5 mb-[56px]">
            <span>
              <NavbatArrow />
            </span>
            <span className={`${open ? "visible" : "hidden"}`}>
              <NavbatIcon />
            </span>
          </li>
          <div className="">
            <StatisticsLink open={open} />

            <PartnersLinks
              open={open}
              showDropdown={showDropdown}
              setShowDropdown={setShowDropdown}
              setOpen={setOpen}
            />
          </div>
        </div>

        <li className="flex flex-col gap-4 sticky bottom-4">
          <Logout open={open}/>
          <div
            className={`transform ${
              open ? "translate-x-[127px] " : "translate-x-0"
            } transition-transform duration-250`}
          >
            <span>
              <ShevronIcon onDrawerClick={onDrawerClick} open={open} />
            </span>
          </div>
        </li>
      </ul>
    </section>
  );
}
