import React from "react";

export default function NavbatIcon() {
  return (
    <svg
      width="111"
      height="22"
      viewBox="0 0 111 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.788408 21.2024C0.585089 21.2024 0.418738 21.1317 0.289353 20.9904C0.159968 20.849 0.0952759 20.6673 0.0952759 20.4452V0.757229C0.0952759 0.535108 0.159968 0.353373 0.289353 0.212025C0.418738 0.0706749 0.585089 0 0.788408 0H3.72729C4.04151 0 4.26331 0.0807711 4.39269 0.242313C4.54056 0.383662 4.63298 0.494722 4.66995 0.575493L11.4349 12.2368V0.757229C11.4349 0.535108 11.4996 0.353373 11.629 0.212025C11.7584 0.0706749 11.9247 0 12.128 0H15.4551C15.6584 0 15.8247 0.0706749 15.9541 0.212025C16.0835 0.353373 16.1482 0.535108 16.1482 0.757229V20.4452C16.1482 20.6471 16.0835 20.8288 15.9541 20.9904C15.8247 21.1317 15.6584 21.2024 15.4551 21.2024H12.4885C12.1927 21.2024 11.9709 21.1216 11.8231 20.9601C11.6937 20.7985 11.6105 20.6875 11.5735 20.6269L4.80857 9.41992V20.4452C4.80857 20.6673 4.74388 20.849 4.6145 20.9904C4.48511 21.1317 4.31876 21.2024 4.11544 21.2024H0.788408Z"
        fill="white"
      />
      <path
        d="M19.1911 21.2024C19.0432 21.2024 18.9046 21.1418 18.7752 21.0206C18.6643 20.8793 18.6088 20.7279 18.6088 20.5663C18.6088 20.4653 18.6181 20.3745 18.6365 20.2937L25.152 0.848095C25.2074 0.625975 25.3183 0.434144 25.4847 0.272602C25.6695 0.0908674 25.9191 0 26.2333 0H30.3366C30.6508 0 30.8911 0.0908674 31.0575 0.272602C31.2423 0.434144 31.3624 0.625975 31.4179 0.848095L37.9056 20.2937C37.9426 20.3745 37.9611 20.4653 37.9611 20.5663C37.9611 20.7279 37.8964 20.8793 37.767 21.0206C37.6561 21.1418 37.5175 21.2024 37.3511 21.2024H33.9409C33.6636 21.2024 33.4511 21.1317 33.3032 20.9904C33.1738 20.8288 33.0907 20.6875 33.0537 20.5663L31.9724 17.4768H24.5698L23.5162 20.5663C23.4792 20.6875 23.3961 20.8288 23.2667 20.9904C23.1373 21.1317 22.9155 21.2024 22.6013 21.2024H19.1911ZM25.7065 13.1152H30.8634L28.2572 5.11886L25.7065 13.1152Z"
        fill="white"
      />
      <path
        d="M44.4254 21.2024C44.1112 21.2024 43.8616 21.1115 43.6768 20.9298C43.492 20.748 43.3626 20.536 43.2886 20.2937L37.6327 0.908674C37.5957 0.807711 37.5772 0.716843 37.5772 0.636072C37.5772 0.47453 37.6327 0.333181 37.7436 0.212025C37.873 0.0706749 38.0208 0 38.1872 0H41.6251C41.9209 0 42.1519 0.0908674 42.3183 0.272602C42.4846 0.454337 42.5863 0.625975 42.6232 0.787518L46.5325 14.7811L50.4695 0.787518C50.5249 0.625975 50.6266 0.454337 50.7745 0.272602C50.9408 0.0908674 51.1719 0 51.4676 0H54.9055C55.0719 0 55.2105 0.0706749 55.3214 0.212025C55.4508 0.333181 55.5155 0.47453 55.5155 0.636072C55.5155 0.716843 55.497 0.807711 55.46 0.908674L49.8041 20.2937C49.7302 20.536 49.6008 20.748 49.4159 20.9298C49.2496 21.1115 49.0001 21.2024 48.6674 21.2024H44.4254Z"
        fill="white"
      />
      <path
        d="M59.0743 21.2024C58.871 21.2024 58.7046 21.1317 58.5752 20.9904C58.4458 20.849 58.3812 20.6673 58.3812 20.4452V0.757229C58.3812 0.535108 58.4458 0.353373 58.5752 0.212025C58.7046 0.0706749 58.871 0 59.0743 0H67.5305C69.1201 0 70.4232 0.242313 71.4398 0.726938C72.4564 1.21157 73.2049 1.90821 73.6855 2.81689C74.1846 3.70537 74.4341 4.76549 74.4341 5.99724C74.4341 6.72418 74.3047 7.37035 74.0459 7.93575C73.8056 8.48095 73.5007 8.93529 73.131 9.29876C72.7798 9.66223 72.4471 9.92473 72.1329 10.0863C72.8353 10.4497 73.4545 11.0454 73.9905 11.8733C74.545 12.7012 74.8222 13.6705 74.8222 14.7811C74.8222 16.1138 74.545 17.2648 73.9905 18.234C73.4545 19.1831 72.6597 19.9201 71.6061 20.4452C70.571 20.95 69.2957 21.2024 67.78 21.2024H59.0743ZM63.344 17.2648H67.2255C68.0203 17.2648 68.621 17.0124 69.0277 16.5076C69.4343 16.0028 69.6376 15.4273 69.6376 14.7811C69.6376 14.0743 69.425 13.4787 68.9999 12.994C68.5933 12.5094 68.0018 12.2671 67.2255 12.2671H63.344V17.2648ZM63.344 8.42037H66.976C67.7338 8.42037 68.2976 8.20835 68.6672 7.7843C69.0554 7.36026 69.2495 6.81505 69.2495 6.14869C69.2495 5.50252 69.0554 4.97751 68.6672 4.57366C68.2976 4.14961 67.7338 3.93758 66.976 3.93758H63.344V8.42037Z"
        fill="white"
      />
      <path
        d="M76.8 21.2024C76.6522 21.2024 76.5135 21.1418 76.3842 21.0206C76.2733 20.8793 76.2178 20.7279 76.2178 20.5663C76.2178 20.4653 76.2271 20.3745 76.2455 20.2937L82.761 0.848095C82.8164 0.625975 82.9273 0.434144 83.0937 0.272602C83.2785 0.0908674 83.528 0 83.8423 0H87.9456C88.2598 0 88.5001 0.0908674 88.6665 0.272602C88.8513 0.434144 88.9714 0.625975 89.0269 0.848095L95.5146 20.2937C95.5516 20.3745 95.5701 20.4653 95.5701 20.5663C95.5701 20.7279 95.5054 20.8793 95.376 21.0206C95.2651 21.1418 95.1264 21.2024 94.9601 21.2024H91.5499C91.2726 21.2024 91.0601 21.1317 90.9122 20.9904C90.7828 20.8288 90.6997 20.6875 90.6627 20.5663L89.5814 17.4768H82.1787L81.1252 20.5663C81.0882 20.6875 81.0051 20.8288 80.8757 20.9904C80.7463 21.1317 80.5245 21.2024 80.2103 21.2024H76.8ZM83.3155 13.1152H88.4724L85.8662 5.11886L83.3155 13.1152Z"
        fill="white"
      />
      <path
        d="M101.019 21.2024C100.816 21.2024 100.649 21.1317 100.52 20.9904C100.39 20.849 100.326 20.6673 100.326 20.4452V4.75539H95.3907C95.1874 4.75539 95.021 4.68472 94.8916 4.54337C94.7623 4.38183 94.6976 4.20009 94.6976 3.99816V0.757229C94.6976 0.535108 94.7623 0.353373 94.8916 0.212025C95.021 0.0706749 95.1874 0 95.3907 0H110.307C110.51 0 110.677 0.0706749 110.806 0.212025C110.935 0.353373 111 0.535108 111 0.757229V3.99816C111 4.20009 110.935 4.38183 110.806 4.54337C110.677 4.68472 110.51 4.75539 110.307 4.75539H105.372V20.4452C105.372 20.6673 105.307 20.849 105.178 20.9904C105.048 21.1317 104.882 21.2024 104.679 21.2024H101.019Z"
        fill="white"
      />
    </svg>
  );
}
