import { createAsyncThunk } from "@reduxjs/toolkit";
import { PAGES_URLS } from "../../../config/pages-url.config";

export const getStatisticsToday = createAsyncThunk(
    "statistics/getStatisticsToday",
    async (_, { rejectWithValue, navigate }) => {
        try {
            const token = localStorage.getItem('admin') ? JSON.parse(localStorage.getItem('admin')).access_token : null;
            if (!token) {
                navigate(PAGES_URLS.LOGIN, { replace: true });
            }

            const url = PAGES_URLS.STATISTICS_TODAY;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                if (response.status === 401) {
                    navigate(PAGES_URLS.LOGIN, { replace: true });
                }
                throw new Error("Network response was not ok");
            }

            const data = await response.json();
            return data;

        } catch (err) {
            return rejectWithValue(err?.message);
        }
    }
);
