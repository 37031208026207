import React from "react";

export default function LoginBtn({ children, isValid }) {
  return (
    <button
      className="w-full border rounded-lg h-[48px] py-3 px-4 bg-[#EE6F42] text-white text-base font-medium"
      type="submit"
      disabled={!isValid}
    >
      {children}
    </button>
  );
}
