import React from "react";
import { Routes, Route } from "react-router-dom";
import Statistics from "./Statistics/Statistics";
import Partners from "./Partners/Partners";
import { PAGES_URLS } from "../../config/pages-url.config";
import NotFoundPage from "./NotFoundPage/NotFoundPage";

export default function ContentRoutes() {
  return (
    <Routes>
      <Route path={PAGES_URLS.STATISTICS} element={<Statistics />} />
      <Route path={PAGES_URLS.PARTNERS} element={<Partners />} />
      <Route path={PAGES_URLS.PARTNERS_UNACTIVE} element={<Partners />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
