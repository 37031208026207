import React from "react";

export default function StatisticSkeleton({label}) {
  return (
    <li className="flex gap-[15.6px] 2xl:gap-[22px] h-[100px] 2xl:h-[134px] p-[18.44px] 2xl:pt-[24px] 2xl:pl-[24px] border-b border-[#EDEDED]">
      <div className="h-[36.88px] min-w-[36.88px] 2xl:h-[52px] 2xl:w-[52px] rounded-[4.26px] 2xl:rounded-md bg-gray-300 animate-pulse"></div>
      <div className="flex flex-col justify-between w-full">
        <div className="mb-2 h-[14px] 2xl:h-[18px] bg-gray-300 animate-pulse w-[40%]"></div>
        <div className="h-[42.67px] 2xl:h-[60px] flex flex-col justify-between">
          <div className="flex h-[23px] 2xl:h-8 mb-[5.67px] 2xl:mb-2 gap-[5.67px] 2xl:gap-2 items-end">
            <div className="h-[22.7px] 2xl:h-8 bg-gray-300 animate-pulse w-[26px] 2xl:w-[36px]"></div>
            <div className="flex h-[17px] 2xl:h-[24px] items-end">
              <div className="h-[14.19px] 2xl:h-5 bg-gray-300 animate-pulse w-[20px] 2xl:w-[30px]"></div>
              <div className="h-[14.19px] 2xl:h-5 bg-gray-300 animate-pulse w-[20px] 2xl:w-[30px] ml-2"></div>
            </div>
          </div>
          <div className="flex gap-[26px]">
            <div className="h-[14.19px] 2xl:h-5 bg-gray-300 animate-pulse w-[40%]"></div>
            {label === "Партнёры" && <div className="h-[14.19px] 2xl:h-5 bg-gray-300 animate-pulse w-[40%]"></div>}
          </div>
        </div>
      </div>
    </li>
  );
}
