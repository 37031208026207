import { Box, Modal, Backdrop, Fade } from "@mui/material";
import React from "react";
import CloseIcon from "../../ui/icons/SideBar/CloseIcon";
import { PAGES_URLS } from "../../../config/pages-url.config";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 340,
  height: 208,
  bgcolor: "#FFFFFF",
  border: "none",
  boxShadow: 24,
  p: "24px",
  borderRadius: "10px",
  outline: "none",
};

export default function LogoutModal({ openModal, setOpenModal }) {
  const navigate = useNavigate();

  const handleClose = () => setOpenModal(false);

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem("admin")
        ? JSON.parse(localStorage.getItem("admin")).access_token
        : null;
      if (!token) {
        throw new Error("No access token found");
      }
      const response = await fetch(PAGES_URLS.LOGOUT, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        localStorage.removeItem("admin");
        toast.success("Вы успешно вышли!", { duration: 4000 });
        setOpenModal(false);
        navigate(PAGES_URLS.LOGIN);
      } else {
        toast.error("Ошибка при выходе");
      }
    } catch (err) {
      toast.error("Произошла ошибка при попытке выхода");
      console.error("Logout error:", err);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openModal}>
          <Box sx={style}>
            <div className="flex justify-end mb-[20px]">
              <CloseIcon setOpenModal={setOpenModal} />
            </div>
            <h1 className="font-medium mb-5 text-[20px] leading-[24px] text-center text-[#1E3148]">
              Вы действительно хотите выйти?
            </h1>
            <div className="flex gap-5">
              <button
                onClick={handleClose}
                className="hover:opacity-85 w-[136px] h-[48px] rounded-lg border border-[#737C86] font-medium text-[#737C86] text-base"
              >
                Отмена
              </button>
              <button
                onClick={handleLogout}
                className="hover:opacity-85 w-[136px] h-[48px] rounded-lg bg-[#FF3232] font-medium text-white text-base"
              >
                Выйти
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
