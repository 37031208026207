import React from "react";

export default function SelectIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9679_29833)">
        <path
          d="M9.99989 9.22607L5.87489 13.3511L4.69656 12.1727L9.99989 6.86941L15.3032 12.1727L14.1249 13.3511L9.99989 9.22607Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_9679_29833">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="matrix(-1 0 0 -1 20 20.2028)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
