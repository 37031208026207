import React from "react";

export default function ArrowUp() {
  return (
    <svg
      // width="24"
      // height="24"
      className="w-[17px] h-[17px] 2xl:w-[24px] 2xl:h-[24px] "
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.35178 11.6481C6.12654 11.4231 6 11.1179 6 10.7997C6 10.4815 6.12654 10.1764 6.35178 9.95135L11.1578 5.15135C11.3831 4.92638 11.6887 4.8 12.0073 4.8C12.3258 4.8 12.6314 4.92638 12.8567 5.15135L17.6627 9.95135C17.8816 10.1777 18.0027 10.4808 18 10.7954C17.9972 11.1101 17.8709 11.411 17.6481 11.6335C17.4253 11.856 17.124 11.9822 16.8089 11.985C16.4939 11.9877 16.1904 11.8667 15.9638 11.6481L13.2088 8.89655V17.9997C13.2088 18.318 13.0822 18.6232 12.8568 18.8483C12.6315 19.0733 12.3259 19.1997 12.0073 19.1997C11.6886 19.1997 11.383 19.0733 11.1577 18.8483C10.9323 18.6232 10.8058 18.318 10.8058 17.9997V8.89655L8.05071 11.6481C7.82539 11.8731 7.51984 11.9995 7.20125 11.9995C6.88265 11.9995 6.5771 11.8731 6.35178 11.6481Z"
        fill="#10B981"
      />
    </svg>
  );
}
