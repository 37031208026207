import React from "react";
import styles from "../partners.module.scss";

export default function TableNames() {
  return (
    <thead className={styles.thead}>
      <tr className={styles.tr}>
        <th className={`${styles.th_id} bg-white`}>
          <p className="text-center w-[60px] bg-white">ID</p>
        </th>
        <th className={`${styles.thName} bg-white`}>
          <p className="w-[58px] h-[32px] text-center">Название салона</p>
        </th>
        <th className={`${styles.th} px-[10.38px]`}>
          <p className="w-[100px] ">Номер телефона</p>
        </th>
        <th className={`${styles.th} px-[14.88px]`}>
          <p className="w-[91px]">Потверждено</p>
        </th>
        <th className={`${styles.th} px-[15.38px]`}>
          <p className="w-[90px] h-[32px]">Созданные записи за день</p>
        </th>
        <th className={`${styles.th} px-[15.38px]`}>
          <p className="w-[90px] h-[32px]">Завершенные записи за день</p>
        </th>
        <th className={`${styles.th} px-[15.38px]`}>
          <p className="w-[90px] h-[32px] ">Отмененные записи за день</p>
        </th>
        <th className={`${styles.th} px-[6.38px]`}>
          <p className="w-[108px] h-[32px]">Созданные записи за неделю</p>
        </th>
        <th className={`${styles.th} px-[6.38px]`}>
          <p className="w-[108px] h-[32px]">Завершенные записи за неделю</p>
        </th>
        <th className={`${styles.th} px-[6.38px]`}>
          <p className="w-[108px] h-[32px]">Отмененные записи за неделю</p>
        </th>
        <th className={`${styles.th} px-[11.38px]`}>
          <p className="w-[98px] h-[32px]">Созданные записи за месяц</p>
        </th>
        <th className={`${styles.th} px-[11.38px]`}>
          <p className="w-[98px] h-[32px]">Завершенные записи за месяц</p>
        </th>
        <th className={`${styles.th} px-[9.88px]`}>
          <p className="w-[101px] h-[32px]">Отмененные записи за месяц</p>
        </th>
        <th className={`${styles.th} px-[4.88px]`}>
          <p className="w-[111px] h-[32px]">Завершенные за прошлый месяц</p>
        </th>
      </tr>
    </thead>
  );
}
