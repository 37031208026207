import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import ContentRoutes from './components/Content/ContentRoutes';
import LogIn from './components/LogIn/LogIn';
import SideBar from './components/SideBar/SideBar';
import { PAGES_URLS } from "./config/pages-url.config";
import { Toaster } from 'react-hot-toast';

function App() {
  const [isAdmin, setIsAdmin] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const isValidTokenStructure = (token) => {
    const parts = token.split('|');
    return parts.length === 2 && parts[0].length > 0 && parts[1].length > 0;
  };
  
  useEffect(() => {
    const admin = localStorage.getItem("admin");
    if (admin) {
      const parsedAdmin = JSON.parse(admin);
      if (parsedAdmin.access_token && isValidTokenStructure(parsedAdmin.access_token)) {
        setIsAdmin(true);
        if (location.pathname === PAGES_URLS.LOGIN || location.pathname === '/') {
          navigate(PAGES_URLS.STATISTICS, { replace: true });
        }
      } else {
        setIsAdmin(false);
        navigate(PAGES_URLS.LOGIN, { replace: true });
      }
    } else {
      setIsAdmin(false);
      navigate(PAGES_URLS.LOGIN, { replace: true });
    }
  }, [location.pathname, navigate]);

  if (isAdmin === null) {
    return null;
  }

  return (
    <div className='flex max-w-[1920px] min-h-screen'>
        <Toaster  />

      {isAdmin ? (
        <>
          <SideBar />
          <Routes>
            <Route path="/*" element={<ContentRoutes />} />
          </Routes>
        </>
      ) : (
        <Routes>
          <Route path={PAGES_URLS.LOGIN} element={<LogIn />} />
          <Route path="*" element={<Navigate to={PAGES_URLS.LOGIN} replace />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
