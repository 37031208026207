import React from "react";
import partnerIcon from "../../../../images/content/partners-icon.png";
import dayIcon from "../../../../images/content/day-icon.png";
import weekIcon from "../../../../images/content/week-icon.png";
import monthIcon from "../../../../images/content/month-icon.png";
import ArrowUp from "../../../ui/icons/Content/ArrowUp";
import ArrowDown from "../../../ui/icons/Content/ArrowDown";
import StatisticSkeleton from "../../../ui/Skeletons/StatisticSkeleton";
import ErrorIcon from "../../../ui/icons/Content/ErrorIcon";

export default function StatisticsBlock({
  label,
  dataes = [],
  status,
  errors,
  isPartners = false,
}) {
  const mappedData = isPartners
    ? [
        {
          id: 0,
          label: "Все партнёры",
          currentMonth: true,
          shares: dataes?.[0]?.comparison,
          all: dataes?.[0]?.all,
          current: dataes?.[0]?.present,
          previous: dataes?.[0]?.last_all,
        },
        {
          id: 1,
          label: "Активные партнёры",
          currentMonth: false,
          shares: dataes?.[1]?.comparison,
          current: dataes?.[1]?.active,
          previous: dataes?.[1]?.last_active,
        },
        {
          id: 2,
          label: "Неактивные партнёры",
          currentMonth: false,
          shares: dataes?.[2]?.unactive > dataes?.[2]?.last_unactive ? -Math.abs(dataes?.[2]?.comparison) : Math.abs(dataes?.[2]?.comparison),
          current: dataes?.[2]?.unactive,
          previous: dataes?.[2]?.last_unactive,
        },
      ]
    : [
        {
          id: 0,
          label: "Созданные записи",
          shares:
            dataes?.[0]?.all === 0 && dataes?.[0]?.last_all > dataes?.[0]?.all
              ? 0
              : dataes?.[0]?.comparison,
          current: dataes?.[0]?.all,
          previous: dataes?.[0]?.last_all,
        },
        {
          id: 1,
          label: "Завершенные записи",
          shares:
            dataes?.[1]?.success === 0 &&
            dataes?.[1]?.last_success > dataes?.[1]?.success
              ? 0
              : dataes?.[1]?.comparison,

          current: dataes?.[1]?.success,
          previous: dataes?.[1]?.last_success,
        },
        {
          id: 2,
          label: "Отмененные записи",
          shares:
            dataes?.[2]?.canceled === 0 &&
            dataes?.[2]?.last_canceled > dataes?.[2]?.canceled
              ? 0
              : dataes?.[2]?.comparison,
          current: dataes?.[2]?.canceled,
          previous: dataes?.[2]?.last_canceled,
        },
      ];
  // console.log('dataes', dataes);
  const getIcon = () => {
    if (isPartners) return partnerIcon;
    switch (label) {
      case "Неделя":
        return weekIcon;
      case "Месяц":
        return monthIcon;
      default:
        return dayIcon;
    }
  };

  return (
    <div className="">
      <h1 className="font-[600] mb-[3px] text-[20px] leading-[22.7px] 2xl:leading-[32px] 2xl:text-[28px] text-[#111827]">
        {label}
      </h1>
      {status === "pending" ? (
        <ul className="shadow-md bg-[#FFFFFF] rounded-lg">
          {Array(3)
            .fill()
            .map((_, index) => (
              <StatisticSkeleton key={index} label={label} />
            ))}
        </ul>
      ) : (
        <ul className="shadow-md bg-[#FFFFFF] rounded-lg">
          {dataes.length > 0 ? (
            mappedData.map((item, index) => (
              <li
                key={item.id}
                className={`${
                  item.shares <= 0 &&
                  item.current !== item.previous &&
                  "bg-[#FFECEC]"
                } flex gap-[15.6px] 2xl:gap-[22px] h-[100px] 2xl:h-[134px] p-[18.44px] 2xl:pt-[24px] 2xl:pl-[24px] ${
                  index !== mappedData.length - 1 ? "border-b" : ""
                } border-[#EDEDED]`}
              >
                <img
                  className="h-[36.88px] w-[36.88px] 2xl:h-[52px] 2xl:w-[52px] rounded-[4.26px] 2xl:rounded-md"
                  src={getIcon()}
                  alt="icon"
                />
                <div>
                  <p className="mb-2 text-[14px] 2xl:text-[18px] font-[500] leading-[14.19px] 2xl:leading-5 text-[#6B7280]">
                    {item.label}
                  </p>
                  <div className="h-[42.67px] 2xl:h-[60px]">
                    <div
                      className={`flex h-[23px] 2xl:h-8 mb-[5.67px] 2xl:mb-2 gap-[5.67px] 2xl:gap-2 items-end`}
                    >
                      <span className="text-[20px] 2xl:text-[28px] w-[26px] 2xl:w-[36px] text-center leading-[22.7px] 2xl:leading-8 text-[#111827] font-[600]">
                        {item.all ? item.all : item.current}
                      </span>
                      <div
                        className={`${
                          item.current === item.previous && "hidden"
                        } flex h-[17px] 2xl:h-[24px] items-end`}
                      >
                        {item.shares > 0 ? <ArrowUp /> : <ArrowDown />}
                        <p
                          className={`font-[500] h-[15px] 2xl:h-[20px] leading-[14.19px] 2xl:leading-5 text-[12px] 2xl:text-[18px] ${
                            item.shares > 0
                              ? "text-[#059669]"
                              : "text-[#DC2626]"
                          }`}
                        >
                          {item.shares < 0
                            ? Math.abs(item.shares)
                            : item.shares}
                          %
                        </p>
                      </div>
                    </div>
                    <div className="flex gap-[26px]">
                      {item.currentMonth && (
                        <p className="text-[12px] leading-[14.19px] 2xl:text-[14px] font-[400] text-[#878F9F]">
                          Текущий месяц: {item.current}
                        </p>
                      )}
                      <p className="text-[12px] leading-[14.19px] 2xl:text-[14px] font-[400] text-[#878F9F]">
                        {`${
                          label === "Месяц" || isPartners
                            ? "Предыдущий месяц: "
                            : label === "Неделя"
                            ? "Предыдущая неделя: "
                            : "Предыдущий день: "
                        }${item.previous}`}
                      </p>
                      {/* {item.previosMonth && (
                        <p className="text-[12px] leading-[14.19px] 2xl:text-[14px] font-[400] text-[#878F9F]">
                          Предыдущий месяц: {item.previosMonth}
                        </p>
                      )} */}
                    </div>
                  </div>
                </div>
              </li>
            ))
          ) : (
            <div className="w-full h-[300px] flex flex-col justify-center items-center">
              <ErrorIcon width={52} height={52} />
              <p className="text-[#1E3148] font-[500] text-[22px] leading-5">
                Данные пока отсутствуют
              </p>
            </div>
          )}
        </ul>
      )}
      {errors && dataes.length === 0 && (
        <div className="w-full bg-white h-[300px] flex justify-center items-center">
          <p className="text-red-500 font-[500] text-[18px]">{errors}</p>
        </div>
      )}
    </div>
  );
}
