import React, { useState } from "react";
import ShowPasswordIcon from "../ui/icons/auth/ShowPasswordIcon";
import UnShowPasswordIcon from "../ui/icons/auth/UnShowPasswordIcon";

export default function InputForm({
  id,
  type,
  name,
  label,
  placeholder,
  autoComplete,
  togglePassword,
  isPasswordNotNull,
  register,
  errors,
  validation
}) {
  const [showPassword, setShowPassword] = useState(false);

  const handleToggle = () => {
    setShowPassword((prevState) => !prevState);
  };

  const fieldType = showPassword ? "text" : type;
  const hasError = errors[name];

  return (
    <div className="flex flex-col gap-1 relative">
      <label className="text-xs font-normal text-[#737C86]" htmlFor={id}>
        {label}
      </label>
      <div className="relative">
        <input
          className={`w-full py-3 px-4 ${name === 'password' ? 'pr-[52px]' : ''} bg-white border ${
            hasError ? 'border-[#DB3232]' : 'border-[#EDEDED]'
          } rounded-lg focus:outline-none focus:bg-transparent`}
          type={fieldType}
          id={id}
          placeholder={placeholder}
          autoComplete={autoComplete}
          {...register(name, validation)}
          maxLength={35}
        />
        {togglePassword && isPasswordNotNull && (
          <button
            type="button"
            onClick={handleToggle}
            className="absolute right-[14px] top-[14px] text-gray-600"
          >
            {showPassword ? <UnShowPasswordIcon /> : <ShowPasswordIcon />}
          </button>
        )}
      </div>
      {hasError && (
        <span className="text-[#DB3232] text-xs font-normal mt-1">
          {errors[name]?.message}
        </span>
      )}
    </div>
  );
}
