import { createSlice } from "@reduxjs/toolkit";
import { getStatisticsToday } from "./apies/getStatisticsToday";
import { getStatisticsWeek } from "./apies/getStatisticsWeek";
import { getStatisticsMonth } from "./apies/getStatisticsMonth";
import { getStatisticsPartners } from "./apies/getStatisticsPartners";

const initialState = {
    unActivePartnersCount: 0,
    todayDataes: [],
    weekDataes: [],
    monthDataes: [],
    partnersDataes: [],
    status: {
        today: null,
        week: null,
        month: null,
        partners: null,
    },
    error: {
        today: null,
        week: null,
        month: null,
        partners: null,
    },
};

const navbatSlices = createSlice({
    name: "navbat",
    initialState,
    reducers: {
        setUnActivePartnersCount: (state, action) => {
            state.unActivePartnersCount = action.payload;
        },
        setTodayDataes: (state, action) => {
            state.todayDataes = action.payload;
        },
        setWeekDataes: (state, action) => {
            state.weekDataes = action.payload;
        },
        setMonthDataes: (state, action) => {
            state.monthDataes = action.payload;
        },
        setPartnersDataes: (state, action) => {
            state.partnersDataes = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            // getStatisticsToday
            .addCase(getStatisticsToday.pending, (state) => {
                state.status.today = "pending";
                state.error.today = null;
            })
            .addCase(getStatisticsToday.fulfilled, (state, action) => {
                state.status.today = "success";
                state.todayDataes = action.payload;
            })
            .addCase(getStatisticsToday.rejected, (state, action) => {
                state.status.today = "rejected";
                state.error.today = action.payload;
            })
            // getStatisticsWeek
            .addCase(getStatisticsWeek.pending, (state) => {
                state.status.week = "pending";
                state.error.week = null;
            })
            .addCase(getStatisticsWeek.fulfilled, (state, action) => {
                state.status.week = "success";
                state.weekDataes = action.payload;
            })
            .addCase(getStatisticsWeek.rejected, (state, action) => {
                state.status.week = "rejected";
                state.error.week = action.payload;
            })
            // getStatisticsMonth
            .addCase(getStatisticsMonth.pending, (state) => {
                state.status.month = "pending";
                state.error.month = null;
            })
            .addCase(getStatisticsMonth.fulfilled, (state, action) => {
                state.status.month = "success";
                state.monthDataes = action.payload;
            })
            .addCase(getStatisticsMonth.rejected, (state, action) => {
                state.status.month = "rejected";
                state.error.month = action.payload;
            })
            // getStatisticsPartners
            .addCase(getStatisticsPartners.pending, (state) => {
                state.status.partners = "pending";
                state.error.partners = null;
            })
            .addCase(getStatisticsPartners.fulfilled, (state, action) => {
                state.status.partners = "success";
                state.partnersDataes = action.payload;
            })
            .addCase(getStatisticsPartners.rejected, (state, action) => {
                state.status.partners = "rejected";
                state.error.partners = action.payload;
            })
    },
});

export const { setTodayDataes, setWeekDataes, setMonthDataes, setPartnersDataes, setUnActivePartnersCount } = navbatSlices.actions;
export default navbatSlices.reducer;
