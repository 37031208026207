import React from "react";

export default function ErrorIcon({width, height}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 79 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="78" height="78" rx="9" fill="#E7E7E7" />
      <path
        d="M26.75 31.6283V33.7746C26.75 35.4824 27.4284 37.1202 28.6359 38.3277C29.8435 39.5353 31.4813 40.2137 33.189 40.2137H35.3354M52.5061 29.482V40.2137C52.5061 41.9214 51.8277 43.5592 50.6202 44.7667C49.4126 45.9743 47.7748 46.6527 46.0671 46.6527H43.9207M35.3354 57.3844V23.0429C35.3354 21.9044 35.7876 20.8126 36.5927 20.0075C37.3977 19.2025 38.4896 18.7502 39.628 18.7502C40.7665 18.7502 41.8584 19.2025 42.6634 20.0075C43.4685 20.8126 43.9207 21.9044 43.9207 23.0429V57.3844M28.8963 57.3844H50.3598"
        stroke="#222222"
        strokeWidth="3.65625"
        strokeLinecap="square"
      />
    </svg>
  );
}
