import React from "react";
import ErrorIcon from "../../ui/icons/Content/ErrorIcon";

export default function NotFoundPage() {
  return (
    <div className="w-full h-screen flex flex-col gap-3 justify-center items-center text-2xl">
      <ErrorIcon width={80} height={80} />
      <p className="text-5xl text-red-500">404</p>
      <p>Not Found Page</p>
    </div>
  );
}
