import React from "react";

export default function StatsIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9691_32226)">
        <path
          d="M2 13.2028H8V21.2028H2V13.2028ZM16 8.20276H22V21.2028H16V8.20276ZM9 3.20276H15V21.2028H9V3.20276ZM4 15.2028V19.2028H6V15.2028H4ZM11 5.20276V19.2028H13V5.20276H11ZM18 10.2028V19.2028H20V10.2028H18Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_9691_32226">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.202759)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
