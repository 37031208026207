import React from "react";
import PartnersIcon from "../../ui/icons/SideBar/PartnersIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { PAGES_URLS } from "../../../config/pages-url.config";
import SelectIcon from "../../ui/icons/SideBar/SelectIcon";
import PartnersDropdown from "./PartnersDropdown/PartnersDropdown";

export default function PartnersLinks({ open, showDropdown, setShowDropdown }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const toggleDropdown = () => {
    if (open) {
      setShowDropdown(!showDropdown);
      // if (pathname !== PAGES_URLS.PARTNERS_UNACTIVE) {
      //   navigate(`${PAGES_URLS.PARTNERS}?page=1`);
      // } else {
      //   navigate(`${PAGES_URLS.PARTNERS_UNACTIVE}?page=1`);
      // }
    } else {
      let path;
      if (pathname === PAGES_URLS.PARTNERS) {
        path = PAGES_URLS.PARTNERS_UNACTIVE;
      } else {
        path = PAGES_URLS.PARTNERS;
      }
      navigate(`${path}?page=1`);
    }
    window.scrollTo(0, 0);
  };

  const IsPartnersInPath = pathname?.includes(PAGES_URLS.PARTNERS);

  return (
    <>
      <li
        className={`flex h-7 w-[157px] justify-between items-center mb-[12px] font-[600] text-[18px] hover:text-[#EE6F42] cursor-pointer ${
          IsPartnersInPath ? "text-[#EE6F42]" : ""
        }`}
        onClick={toggleDropdown}
      >
        <span>
          <PartnersIcon />
        </span>
        <div
          className={`${
            open ? "visible" : "hidden"
          } flex gap-[16.7px] items-center`}
        >
          <span className="w-[95px] pl-[6px]">Партнёры</span>
          <span className={`${!showDropdown && "rotate-180"}`}>
            <SelectIcon />
          </span>
        </div>
      </li>
      {showDropdown && open && <PartnersDropdown open={open} />}
    </>
  );
}
