import React from "react";

export default function ArrowDown() {
  return (
    <svg
      // width="24"
      // height="24"
      className="w-[17px] h-[17px] 2xl:w-[24px] 2xl:h-[24px]"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6482 12.3516C17.8735 12.5766 18 12.8818 18 13.2C18 13.5182 17.8735 13.8234 17.6482 14.0484L12.8422 18.8484C12.6169 19.0734 12.3113 19.1997 11.9927 19.1997C11.6742 19.1997 11.3686 19.0734 11.1433 18.8484L6.33728 14.0484C6.11841 13.8221 5.99731 13.5189 6.00005 13.2043C6.00278 12.8897 6.12914 12.5887 6.35191 12.3662C6.57468 12.1437 6.87603 12.0175 7.19106 12.0148C7.50609 12.012 7.80959 12.133 8.0362 12.3516L10.7912 15.1032V5.99999C10.7912 5.68173 10.9178 5.3765 11.1432 5.15146C11.3685 4.92642 11.6741 4.79999 11.9927 4.79999C12.3114 4.79999 12.617 4.92642 12.8423 5.15146C13.0677 5.3765 13.1942 5.68173 13.1942 5.99999V15.1032L15.9493 12.3516C16.1746 12.1266 16.4802 12.0002 16.7988 12.0002C17.1173 12.0002 17.4229 12.1266 17.6482 12.3516Z"
        fill="#EF4444"
      />
    </svg>
  );
}
