import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStatisticsToday } from "../../../redux/slices/apies/getStatisticsToday";
import { getStatisticsWeek } from "../../../redux/slices/apies/getStatisticsWeek";
import { getStatisticsMonth } from "../../../redux/slices/apies/getStatisticsMonth";
import { getStatisticsPartners } from "../../../redux/slices/apies/getStatisticsPartners";
import { useNavigate } from "react-router-dom";
import StatisticsBlock from "./StatisticBlock/StatisticsBlock";
export default function Statistics() {
  const {
    todayDataes,
    weekDataes,
    monthDataes,
    partnersDataes,
    status,
    error,
  } = useSelector((state) => state.navbat);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getStatisticsToday({ navigate }));
    dispatch(getStatisticsWeek({ navigate }));
    dispatch(getStatisticsMonth({ navigate }));
    dispatch(getStatisticsPartners());

    const intervalId = setInterval(() => {
      dispatch(getStatisticsToday({ navigate }));
      dispatch(getStatisticsWeek({ navigate }));
      dispatch(getStatisticsMonth({ navigate }));
      dispatch(getStatisticsPartners({ navigate }));
    }, 30 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [dispatch, navigate]);
  // console.log('dataes', monthDataes?.month);

  return (
    <section className="w-full grid grid-cols-2 h-full gap-[13px] 2xl:gap-[14px] pl-[27px] pr-[29px] 2xl:px-[34px] pt-[19px] 2xl:pt-[40px] pb-[19px] 2xl:pb-[20px]">
      <StatisticsBlock
        label="День"
        status={status?.today}
        errors={error["today"]}
        dataes={todayDataes?.today}
      />
      <StatisticsBlock
        label="Неделя"
        status={status?.week}
        errors={error["week"]}
        dataes={weekDataes?.week}
      />
      <StatisticsBlock
        label="Месяц"
        status={status?.month}
        errors={error["month"]}
        dataes={monthDataes?.month}
      />
      <StatisticsBlock
        label="Партнёры"
        status={status?.partners}
        errors={error["partners"]}
        dataes={partnersDataes?.partners}
        isPartners={true}
      />
    </section>
  );
}
