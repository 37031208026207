class URLS {
    BASE_URL = "https://api.navbat.tj/api/"
    STATISTICS = '/statistics';
    MIDDLE_URL = `v1/admin`
    STATISTICS_TODAY = `${this.BASE_URL}${this.MIDDLE_URL}/statistics/today`;
    STATISTICS_WEEK = `${this.BASE_URL}${this.MIDDLE_URL}/statistics/week`;
    STATISTICS_MONTH = `${this.BASE_URL}${this.MIDDLE_URL}/statistics/month`;
    STATISTICS_PARTNERS = `${this.BASE_URL}${this.MIDDLE_URL}/statistics/partners`;
    PARTNERS = '/partners'; 
    PARTNERS_API = `${this.BASE_URL}${this.MIDDLE_URL}/partners`;
    PARTNERS_UNACTIVE = `${this.PARTNERS}/unactive`;
    AUTH = '/auth';
    LOGIN_API = `${this.BASE_URL}${this.MIDDLE_URL}${this.AUTH}/login`;
    LOGIN = '/login';
    LOGOUT = `${this.BASE_URL}${this.MIDDLE_URL}${this.AUTH}/logout`;
}

export const PAGES_URLS = new URLS() 