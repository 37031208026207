import React from "react";

export default function CloseIcon({setOpenModal}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer"
      onClick={() => setOpenModal(false)}
    >
      <g clipPath="url(#clip0_10541_43657)">
        <path
          d="M12 10.586L16.95 5.63599L18.364 7.04999L13.414 12L18.364 16.95L16.95 18.364L12 13.414L7.04999 18.364L5.63599 16.95L10.586 12L5.63599 7.04999L7.04999 5.63599L12 10.586Z"
          fill="#A4AFBD"
        />
      </g>
      <defs>
        <clipPath id="clip0_10541_43657">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
