import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { PAGES_URLS } from "../../../../config/pages-url.config";
import { useSelector } from "react-redux";

const partnersLink = [
  {
    id: 1,
    title: "Все",
    path: PAGES_URLS.PARTNERS,
  },
  {
    id: 2,
    title: "Неактивные",
    path: PAGES_URLS.PARTNERS_UNACTIVE,
  },
];

export default function PartnersDropdown() {
  const { pathname, search } = useLocation();
  const { unActivePartnersCount } = useSelector((state) => state.navbat);

  const createNavLinkPath = (basePath) => {
    const params = new URLSearchParams(search);
    const currentPage = params.get("page") || 1;

    if (pathname !== basePath) {
      window.scrollTo(0, 0);
      return `${basePath}?page=1`;
    }

    return `${basePath}?page=${currentPage}`;
  };

  const isPathActive = (path) => {
    return pathname === path;
  };

  return (
    <div className="rounded-md">
      <ul className="w-[157px]">
        {partnersLink.map((item) => (
          <li key={item.id}>
            <NavLink
              to={createNavLinkPath(item.path)}
              className={
                isPathActive(item.path) ? "text-[#EE6F42]" : "text-white"
              }
            >
              <div
                className={`flex gap-3 items-center pl-3 py-2 cursor-pointer text-[18px] font-[600]`}
              >
                {item.title}{" "}
                {item.title === "Неактивные" && unActivePartnersCount > 0 && (
                  <div className="h-[22px] w-[22px] rounded-xl flex items-center justify-center bg-[#FFAFA3] text-[12px] text-[#B40000] font-[400]">
                    {unActivePartnersCount}
                  </div>
                )}
              </div>
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
}
