import React from "react";
import styles from "../partners.module.scss";
import { useNavigate } from "react-router-dom";
import { PAGES_URLS } from "../../../../config/pages-url.config";

export default function TableDataes({ data = [], error, isLoading }) {
  const navigate = useNavigate();

  if (error) {
    if (error.response && error.response.status === 401) {
      navigate(PAGES_URLS.LOGIN, { replace: true });
    } else if (error.response && error.response.status === 500) {
      return (
        <div className="w-full h-screen flex flex-col justify-center items-center">
          <h1>Ошибка 500</h1>
          <p className="text-2xl">Что-то пошло не так</p>
        </div>
      );
    }
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <p className="text-2xl">Что-то пошло не так</p>
      </div>
    );
  }

  if (data.length === 0) {
    return (
      <tbody className={``}>
        <tr className={``}>
          <td
            className={`h-[77vh]`}
          >
            <p className="text-center fixed  top-[50%] left-[46%] text-[32px] leading-[28px] text-[#1E3148] font-medium">Список пока пуст</p>
          </td>
        </tr>
      </tbody>
    );
  }

  return (
    <tbody className={`${styles.tbody}`}>
      {data.length > 0 &&
        data.map((item) => (
          <tr
            key={item.id}
            className={`${styles.body_tr} ${
              item.createdBookingThisWeek === 0 && "bg-[#FFECEC]"
            }`}
          >
            <td
              className={`${styles.body_tb_id} ${
                item.createdBookingThisWeek > 0 ? "bg-white" : "bg-[#FFECEC]"
              } w-[60px] `}
            >
              <p className="">{item.id}</p>
            </td>
            <td
              className={`${styles.body_tbName} ${
                item.createdBookingThisWeek > 0 ? "bg-white" : "bg-[#FFECEC]"
              } `}
            >
              <div className="flex gap-2 items-center">
                {item.createdBookingThisWeek === 0 && (
                  <div
                    className={`${styles.blink} min-w-[18px] h-[18px] w-[18px] rounded-full bg-[#FF0000]`}
                  ></div>
                )}
                <p className={""}>{item.name}</p>
              </div>
            </td>
            <td className={`${styles.body_tb}`}>{item.phone}</td>
            <td className={`${styles.body_tb}`}>
              {item.is_verified ? "Да" : "Нет"}
            </td>
            <td className={`${styles.body_tb}`}>{item.createdBookingToday}</td>
            <td className={`${styles.body_tb}`}>
              {item.completedBookingToday}
            </td>
            <td className={`${styles.body_tb}`}>{item.canceledBookingToday}</td>
            <td className={`${styles.body_tb}`}>
              {item.createdBookingThisWeek}
            </td>
            <td className={`${styles.body_tb}`}>
              {item.completedBookingThisWeek}
            </td>
            <td className={`${styles.body_tb}`}>
              {item.canceledBookingThisWeek}
            </td>
            <td className={`${styles.body_tb}`}>
              {item.createdBookingThisMonth}
            </td>
            <td className={`${styles.body_tb}`}>
              {item.completedBookingThisMonth}
            </td>
            <td className={`${styles.body_tb}`}>
              {item.canceledBookingThisMonth}
            </td>
            <td className={`${styles.body_tb}`}>
              {item.completedBookingLastMonth}
            </td>
          </tr>
        ))}
    </tbody>
  );
}
