import React from "react";

export default function PartnersIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9679_31301)">
        <path
          d="M4 22.2028C4 20.081 4.84285 18.0462 6.34315 16.5459C7.84344 15.0456 9.87827 14.2028 12 14.2028C14.1217 14.2028 16.1566 15.0456 17.6569 16.5459C19.1571 18.0462 20 20.081 20 22.2028H18C18 20.6115 17.3679 19.0854 16.2426 17.9601C15.1174 16.8349 13.5913 16.2028 12 16.2028C10.4087 16.2028 8.88258 16.8349 7.75736 17.9601C6.63214 19.0854 6 20.6115 6 22.2028H4ZM12 13.2028C8.685 13.2028 6 10.5178 6 7.20277C6 3.88777 8.685 1.20277 12 1.20277C15.315 1.20277 18 3.88777 18 7.20277C18 10.5178 15.315 13.2028 12 13.2028ZM12 11.2028C14.21 11.2028 16 9.41277 16 7.20277C16 4.99277 14.21 3.20277 12 3.20277C9.79 3.20277 8 4.99277 8 7.20277C8 9.41277 9.79 11.2028 12 11.2028Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_9679_31301">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.202774)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
