import React from "react";
import Pagination from "@mui/material/Pagination";
import { PaginationItem } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

export default function TableFooter({
  rowsPerPage,
  totalRows,
  currentPage,
  onPageChange,
}) {
  const { pathname } = useLocation();

  return (
    <tfoot>
      <tr>
        <td colSpan="100%">
          <div
            id="table-footer"
            className={` flex justify-end relative w-full bg-white h-[58px]`}
          >
            <div className="sticky right-2 px-2 flex gap-[34px] h-[58px] items-center">
              <Pagination
                sx={{
                  "& .Mui-selected": {
                    background: "#EE6F42 !important",
                    color: "#FFFFFF !important",
                  },
                  "& .Mui-selected:hover": {
                    background: "#EE6F42 !important",
                    color: "#FFFFFF !important",
                  },
                  "& .MuiButtonBase-root": {
                    fontSize: "12px !important",
                    fontWeight: "400 !important",
                    lineHeight: "16px !important",
                    color: "#A4AFBD",
                    borderRadius: "8px !important",
                    height: "28px !important",
                    width: "28px !important",
                  },
                  color: "#FFFFFF !important",
                }}
                size="small"
                page={currentPage}
                onChange={onPageChange}
                count={Math.ceil(totalRows / rowsPerPage) || 1}
                siblingCount={0}
                boundaryCount={1}
                shape="rounded"
                defaultPage={1}
                renderItem={(item) => (
                  <PaginationItem
                    component={Link}
                    to={`${pathname}?page=${item.page}`}
                    {...item}
                  />
                )}
              />
            </div>
          </div>
        </td>
      </tr>
    </tfoot>
  );
}
