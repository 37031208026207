import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import InputForm from "./Input";
import LoginBtn from "./LoginBtn";
import toast from "react-hot-toast";
import axios from "axios";
import { PAGES_URLS } from "../../config/pages-url.config";

export default function LogIn() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });

  const onSubmit = async (data) => {
    try {
      const url = PAGES_URLS.LOGIN_API;
      const response = await axios.post(url, data);

      if (response.status === 200) {
        toast.success("Вы успешно вошли!", {
          duration: 2000,
          position: "top-center",
        });
        localStorage.setItem("admin", JSON.stringify(response.data));
        navigate(PAGES_URLS.STATISTICS);
      } else {
        toast.error("Произошла ошибка. Пожалуйста, попробуйте ещё раз.");
      }
    } catch (err) {
      // if (err.response) {
        toast.error(
          "Ошибка при входе: " +
            (err?.message || "Неверная почта или пароль")
        );
        // console.log('me1', err.message);
      // } 
      // else {
      //   toast.error("Неверная почта или пароль");
      //   console.log('me');
      // }
    }
  };

  const password = watch("password");

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-[320px] h-[336.5px] flex flex-col gap-[28px]"
      >
        <h1 className="w-full text-center focus:outline-none text-[28px] leading-[33.18px] font-medium">
          Вход
        </h1>
        <div className="flex flex-col gap-3">
          <InputForm
            id="email"
            type="email"
            name="email"
            label="Почта"
            placeholder="Ваша почта"
            autoComplete="email"
            register={register}
            errors={errors}
            validation={{
              required: "Введите почту",
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: "Почта не корректна",
              },
            }}
          />
          <InputForm
            id="password"
            type="password"
            name="password"
            label="Пароль"
            placeholder="Ваш пароль"
            autoComplete="current-password"
            register={register}
            errors={errors}
            validation={{
              required: "Введите пароль",
              minLength: {
                value: 8,
                message: "Минимальная длина пароля 8 символа",
              },
            }}
            togglePassword={true}
            isPasswordNotNull={!!password}
          />
        </div>
        <LoginBtn isValid={isValid}>Log In</LoginBtn>
      </form>
    </div>
  );
}
