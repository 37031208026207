import React from "react";

export default function NavbatArrow() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1.63095C0 0.730202 0.730202 0 1.63095 0H19.5714C20.4722 0 21.2024 0.730204 21.2024 1.63095V19.5718C21.2024 20.4726 20.4722 21.2028 19.5714 21.2028H16.2076C15.3068 21.2028 14.5766 20.4726 14.5766 19.5718L14.5766 11.3098L7.36181 18.5246C6.72488 19.1615 5.69222 19.1615 5.05529 18.5246L2.6767 16.146C2.03977 15.5091 2.03977 14.4764 2.6767 13.8395L9.89044 6.62574H1.63095C0.730202 6.62574 0 5.89554 0 4.99479V1.63095Z"
        fill="#EE6F42"
      />
    </svg>
  );
}
