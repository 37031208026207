import React, { useState, useEffect } from "react";
import styles from "./partners.module.scss";
import TableFooter from "./TableFooter/TableFooter";
import { useLocation, useNavigate } from "react-router-dom";
import { PAGES_URLS } from "../../../config/pages-url.config";
import TableNames from "./PartnersDataes/TableNames";
import TableDataes from "./PartnersDataes/TableDataes";
import { useQuery } from "react-query";
import axios from "axios";
import toast from "react-hot-toast";
import Loader from "../../ui/Loaders/Loader";
import { useDispatch } from "react-redux";
import { setUnActivePartnersCount } from "../../../redux/slices";
import ErrorIcon from "../../ui/icons/Content/ErrorIcon";

export default function Partners() {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getCurrentPageFromUrl = () => {
    const params = new URLSearchParams(search);
    return Number(params.get("page")) || 1;
  };

  const [currentPage, setCurrentPage] = useState(getCurrentPageFromUrl);

  useEffect(() => {
    setCurrentPage(getCurrentPageFromUrl());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const fetchPartners = async (page) => {
    let isActive;
    if (pathname.includes(PAGES_URLS.PARTNERS_UNACTIVE)) {
      isActive = false;
    } else {
      isActive = true;
    }
    const isActivePartners = !isActive ? `type=unactive&` : '';

    const url = `${PAGES_URLS.PARTNERS_API}?${isActivePartners}page=${page}`;

    const token = localStorage.getItem("admin")
      ? JSON.parse(localStorage.getItem("admin")).access_token
      : null;
    if (!token) {
      navigate(PAGES_URLS.LOGIN, { replace: true });
    }

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  const {
    data: partnersDataes,
    isLoading,
    error,
  } = useQuery(
    ["partners", currentPage, pathname],
    () => fetchPartners(currentPage),
    {
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      refetchOnWindowFocus: false,
      onError: (err) => {
        if (err.response && err.response.status === 401) {
          navigate(PAGES_URLS.LOGIN, { replace: true });
          localStorage.removeItem("admin");
          toast.error("Unauthorized");
        } else {
          toast.error("Error fetching data");
          console.error("Error fetching data:", err);
        }
      },
    }
  );

  useEffect(() => {
    dispatch(setUnActivePartnersCount(partnersDataes?.inactiveCount || 0));
  }, [dispatch, partnersDataes?.inactiveCount]);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    navigate(`${pathname}?page=${newPage}`);
  };

  if (isLoading) {
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <Loader />
      </div>
    );
  }

  if (error) {
    if (error.response && error.response.status === 401) {
      navigate(PAGES_URLS.LOGIN, { replace: true });
    } else {
      return (
        <div className="w-full h-screen flex flex-col justify-center items-center">
          <div className="w-[318px] h-[210px] flex flex-col justify-between items-center">
            <ErrorIcon />
            <h1 className="text-5xl text-[#1E3148] font-medium">
              Ошибка {error && error.response && error.response.status}
            </h1>
            <p className="text-[32px] leading-[28px] text-[#737C86] font-normal">
              Что-то пошло не так
            </p>
          </div>
        </div>
      );
    }
  }

  return (
    <div className="overflow-x-hidden min-h-screen pt-6 2xl:pt-[87px] pl-5 2xl:pl-[33px] pr-6 2xl:pr-[45px] pb-5 2xl:pb-[56px]">
      <div className={`${styles.partnersMain} flex flex-col rounded-md bg-white`}>
        {partnersDataes && (
          <>
            <div className="overflow-x-auto no-scrollbar">
              <table className={`${styles.table}`}>
                <TableNames />
                <TableDataes
                  data={partnersDataes.partners.data}
                  error={error}
                  isLoading={isLoading}
                />
                <TableFooter
                  rowsPerPage={partnersDataes.partners.per_page}
                  totalRows={partnersDataes.partners.total}
                  currentPage={partnersDataes.partners.current_page}
                  onPageChange={handlePageChange}
                />
              </table>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
