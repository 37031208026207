import React from "react";

export default function ShevronIcon({open, onDrawerClick}) {
  return (
    <svg
      onClick={onDrawerClick}
      className={`transform ${
        open && "rotate-180 "
      } transition-transform duration-250 cursor-pointer`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 5L20 12L13 19M5 5L12 12L5 19"
        stroke="#EE6F42"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
}
