import React from "react";
import LogoutIcon from "../../ui/icons/SideBar/LogoutIcon";
import LogoutModal from "./LogoutModal";

export default function Logout({ open }) {
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpen = () => setOpenModal(true);

  const textColor = openModal ? 'text-[#EE6F42]' : 'text-[#FFFFFF]';

  return (
    <>
      <div
        onClick={handleOpen}
        className={`flex gap-[6px] ${textColor} hover:text-[#EE6F42] cursor-pointer overflow-hidden`}
      >
        <span className={``}>
          <LogoutIcon />
        </span>
        {open && (
          <p className={`font-medium text-[18px] leading-7 `}>
            Выход
          </p>
        )}
      </div>
      <LogoutModal openModal={openModal} setOpenModal={setOpenModal} />
    </>
  );
}
